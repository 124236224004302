import "../../core/src/components/doc-properties/table.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW247bIBB971eglSolUkkdbzZN2a5U9Qf6AdU+TMzYocWAADtJq/33CnxZkjjtbvuUGJiZM2fODCyOG5NvZE5+vSGE0u4rY2S5zszhPllbMtKCnVEKZSkU0lIrT109D2e4cEbCkZFSYjTaC+53jCyz7G34/N44L8ojLbTyqDwjBSqPNmxFNyXUQh4nA4By/XaMZIBzoSq61d7rmpHlJqB8erPoQN7GNC7ghF/KhcXCC60YKbRsahV2KjCMbMwU6BoOtF86wYVceG27rRQTI9nkQSc40v7MO5KvzGGeIF5NI96hqHaekdu+CmOMVfddg62EGmnoM7jg2RkokG7R7xFjviBFpajwWLu0CoWW2p7l2ebU48FTh4VWHGxXgFiVfY/uLsuSVO6uk8/IchJfLAwqHjE01gUQRosTcTjxExnJs5NCr2Osl8IezT5Es6Rg6464idhbbTlaaoGLxvW8P/thO92ijd62UPyorG4Up1fwSDiipcm5aPy1RSvhBN7mf+SbkHWlUcc4H18b50Q2zoP159EHBjmW0Eh/P0HzGB5i+EHiWcK214aRbHFnDsRpKfgVKoUKTfWls+lM55MdHJokQiSpULf/TvPoo3itD6Od6NYsSvCixcQbf5k3q/fpEIxs5R2sbxw8UGO1QetDh0kJxiF/uPG2wZtHMh1JaZXiwLj7dyW9ZmJcdts43FaJcoeZssqykfL16WAerqSp1pwafp1Ok+RcW50nuBwmwHQ+otBqepRgPwIGn39yYKyoz8zLaKQNFMIf2Yk8ee95+uQyOdlF9hbUoK34v9S2JtkidwTBIRWK6iYlourEcqmR0VvwEOTmwePsY8axSrHvzubetYm33wmP87O7tABZzK5fqOQ9yad7+UCfL8V2F9YCS6XUe3pkBBqvE4iC7cDNzpqiBdngY7eItfHHIfH5dFN8DlICodCSVuDeaOvJLOIinx7IepWZQ2e5SB9Rf3kyHVxM7+nZ7LY3SxplUOVTQFEjF0CMFcqn0Va92RnsE9fwgjPbjhAXav1wow2qXgkXr4zbfEA1GuP1AE+/AUvGV9tbCgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionContainer = 'y8p28ld';
export var backLinksMenu = 'y8p28lh';
export var backlinksList = 'y8p28l8';
export var collapsedIcon = 'y8p28lg';
export var fontSize = 'var(--y8p28l1)';
export var pageInfoDimmed = 'y8p28l6';
export var propertyActionButton = 'y8p28le';
export var propertyConfigButton = 'y8p28lf';
export var propertyRootHideEmpty = 'y8p28li';
export var root = 'y8p28l2';
export var rootCentered = 'y8p28l3';
export var tableBodyRoot = 'y8p28lb';
export var tableBodySortable = 'y8p28lc';
export var tableHeader = 'y8p28l4';
export var tableHeaderBacklinksHint = 'y8p28l7';
export var tableHeaderCollapseButtonWrapper = 'y8p28l5';
export var tableHeaderDivider = 'y8p28la';
export var tableHeaderTimestamp = 'y8p28l9';