import "../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WTwXKCMBCG7z5FjvUQByxaGx+mE2GBbUPCJCtiO333ToJWQC32RLLZ3fzfn2XxFrfrMm6aiH3NGCsBi5IEi6OoKbczxg6YUdntD36foauVPAqWK2h94H3vCPMjT40m0CRYCprA+iOpsNAcCSrXD+dGE3f4CYI10j5xLvMcNfAQ30kHc59VG4eERgtmQUnCBraz79niLDcOclOjjBUMdQkWaZCwDAmXLnLnjNoT+N5kasEiv1KQ02lpO/LoJuUdlCt4V8sU+A7oAKADhswy1IVg8bpuWbyM6nYg8znIvLovNWpfaV7IWrBkVJL00QcOErTEa4uVtF6TMnY+abirLjmH0+uvouBCaJdBaqzsPNRGwwAq8Uybkb7VNJI3Y1CzvsukUH+MWR7VOQ3ek/ByW7b/8gwtpF3vDuOPmaikLVDzMGPJ+Lk34ZJ+SjcRv7GdITKVYPFyVPkaKv+F/6idE6Nx+x86C0265/8BLJpvskgEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_1x6h1vv1';
export var centerContent = '_1x6h1vv7';
export var editSettingsLink = '_1x6h1vv9';
export var prompt = '_1x6h1vv8';
export var promptLink = '_1x6h1vv6';
export var promptLinks = '_1x6h1vv5';
export var root = '_1x6h1vv0';
export var topNav = '_1x6h1vv2';
export var topNavLink = '_1x6h1vv4';
export var topNavLinks = '_1x6h1vv3';