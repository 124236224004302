import "../../core/src/components/affine/import-modal/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/import-modal/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VUzW7bMAy+5yl4GdAcFDg/TTPt1hfYM8gWbbNVJEGSY2fD3n2QnDhO6qYdhp4EkR9F8uNHLTw+rYuXDH7PAFqSoeawzLJvP2YANVJVh8tdkrdKHDmUCrtoyE3HPP0iXXHIjZPoWG6SJyKYJIdFIKM5FEY1ex09QlGlGQXcew4F6oAuml8aH6g8ssLogDqMXVZImVKsMtvBamNThkpYDstVvPyZLfoulve6EE0wqTSjQ6waORyEe2BMlCVpZMles+18ALWnyG2WRZuKqPNrV6Ejz3xUzmqqnMgMh+Ukn++zNtXtOj0fjOUDKa4vrr8OwM1/0+L3839h4Ia/Tc9fYZRxN3EBu8A8FkZL4eL0lXFjCh9T6VORivTrgAcoGucjxhrqdTM8sZ2cwr1uc+HxK/u1jvaT3T5NlfqhSpxpB4nEFZlcJ9Qy2vfCVaRZL5rsSiW7lPyz2d4k8FYUyHIMLeK9Rf9AhqmLk5qHxd/Z7iR+OP8zTkhqfHJdrByWtgNvFMlr2g8rpsQRHSPtSeLz+a+KR5pfLorXyplGS/4mMm9CMPqi0vHEdrw2B3SJuntv9NkvCJbCfh7QKXGcFjBAcEJ76mm/hMJinflRCd+n5/YO/QOn2ZnlJMmE5qCwDDef1KdV/MVL9dgvVVtTQJbExkGb1gkbzZHNUpmWQ01SRgEO/IjEz0n4bqy2AZKPIZGDAfEXFeTN9R8HAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = 'se73cj3';
export var importItem = 'se73cj8';
export var importItemLabel = 'se73cj9';
export var importItemPrefix = 'se73cja';
export var importItemSuffix = 'se73cjb';
export var importModalButtonContainer = 'se73cj7';
export var importModalContainer = 'se73cj0';
export var importModalContent = 'se73cj2';
export var importModalTip = 'se73cj4';
export var importModalTitle = 'se73cj1';
export var importStatusContent = 'se73cj6';
export var link = 'se73cj5';