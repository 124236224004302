import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227jNhB991dMAyxgA6EhK443qzRA0F9oX4oiWIzEkc0NRaokfUvhfy9IyrIsXzZZ5MnGXM+ZG/X8StvSYEUWvk+Kavbv9scU/hsAlEZX4Q+ArrEQbptB8jgA2A0AnO6rJlG1GzyfBry/HhDAGVS21KbK4l+Jjv4esvTLCGyBkobJ+Nts9JPkl8Ik+yCT0RWMsxDzNPQvITxH9RMwfu3X8XywSZJ8OS3WpcRX8j28L1/y3mQdYLvBeJ8lDz61tsIJrTIoxYa4txLKkms6kGPxOjd6qTgrtNQmgxWaIWNYlkIR66grzVFGo5DrjQnFadNzaKTROtihEhVGBIdFmNwnlYVSmzUabh+PYI+9B1NaUSDQ8feynm2lcyGj4U+orFImcUuGRZcWYSdc8Z6KcWFridsMSkmbQFCKuWLCUWUzKEg5Ml78Y2mdKLes0MqRcl3V+0rXBfYPR4esXErJbGGI1NONM0u6eYmIkXOh5hkk8Juoam0cKncc4ANFbWxL5PSn352/dN33ORyg2MhimYuC5fQmyAyT8TS9heQWkvH9wy1MjqeAlUJKz5Gyo/5HhvvAdy9wDc945RgWTqwim5WgNQv7EBrHFFZ0APntHDsrBac/tHPNDp4h9xWScXpngdDS51DoJP0QA/TRs6xvpCUftiRHl2jMPq9H1yHgRQgPH61kWzgeQrK2qEkGj0eCSV+QRsFacLfYL1jrHWguSMwXrq+LJaiEYuf1adTjptUXKAt/elcLYHCX1pvWokneGqyBQZo0BrneMCvewr7m2nAyLNfhkJRaOa+i3mEI8hwtjVqrdYNhmoSbJL3ZHtdkPHvsHgWfGdJpHXIcjpshiX7+PvYK6BUZiVtWo6LuaxBYLZDrdc+31rX3aZSNbWBtkIulzWCSRmh66TyPw0W6fPSuzUco+MmQhD6dTEor7XSt9e/2ujXsYU+OppV67wfmVsulCyV2us4gbaiaGNV35pfegzJ+QFyblwWbnY7L7Oy4TO8jXTMXiuXhPO270sk5DznX2nCWG8LXDMIP85KwcQvhiNkaC8qgNsTWBuvQ1xWZUup1EBx77XscKD7dlCgt3bycvMfCP5yMVqSc7Y/HsWt/Mvq+uHTa+z5XxAVCHCtAxWEY2g+/P8Fs6lc1uPeQ9N56/wHASPH4AdrsW1tAUquhxZIYGkIWviAa3e3hGOwGu/8BcVn/OCkMAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var widthVar = 'var(--_1cm6qyj0)';